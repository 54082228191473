<template>
  <div class="sev">
      <div class="content">
        <h1>课题基金</h1>
        <div class="con_wrap">
          <itemTitle title="简介"></itemTitle>
          <div class="intr grid bg">
            <div class="item bg_blue">
              <h4>院校(区)级课题立项书设计</h4>
              <p>针对需要申请院校级课题或完成毕业课题的医生、老师和在校学生，根据提供的信息进行评估，协助制定符合院校(区)级要求的、可行性强的课题立项书设计、指导、修改、润色服务。</p>
            </div>
            <div class="item">
              <h4>市级课题立项书设计</h4>
              <p>针对需要申请市级课题的临床医生和医学科研人员，深入评估需求，匹配相同研究领域的资深科研团队，研究专业热点问题，协助课题设计和立项书撰写，提高命中率。可对有需要的用户提供从课题申请到结题的全过程协助服务。</p>
            </div>
            <div class="item bg2">
              <h4>省级课题立项书设计</h4>
              <p>针对需要申请省级课题的临床医生和医学科研人员，深入评估需求，匹配相同研究领域的资深科研团队，研究专业热点问题，协助课题设计和立项书撰写，提高命中率。可对有需要的用户提供从课题申请到结题的全过程协助服务。</p>
            </div>
            <div class="item bg_blue no_bg">
              <h4>国家级课题立项书设计</h4>
              <p>针对有国自然基金申请需求的临床医生和医学科研人员，深入评估需求，匹配相同研究领域、具有丰富国自然申请经验的科研团队，研究专业热点问题，协助课题设计和立项书撰写，提高命中率。可对有需要的用户提供从课题申请到结题的全过程协助服务。</p>
            </div>
          </div>
        </div>
        <div class="con_wrap">
          <itemTitle title="研究热点"></itemTitle>
          <div class="intr">
            <img :src="require('@/assets/Images/Service/hotspot.jpg')" alt="">
          </div>
        </div>
      </div>
      <div class="content">
        <h1>SCI服务</h1>
        <div class="con_wrap">
          <itemTitle title="简介"></itemTitle>
          <div class="intr2">
            <div class="item" v-for="(intr,index) in intrList" :key="index">
              <img :src="intr.url" alt="">
              <p>{{intr.content}}</p>
            </div>
          </div>
        </div>
        <div class="con_wrap">
          <itemTitle title="医学基础科研的逻辑思维方式"></itemTitle>
          <div class="intr">
            <img :src="require('@/assets/Images/Service/logic.jpg')" alt="">
<!--            <tDemo></tDemo>-->
          </div>
        </div>
      </div>
    <div class="content">
      <div class="con_wrap">
        <itemTitle title="实验外包"></itemTitle>
        <sevList></sevList>
      </div>
    </div>
  </div>
</template>

<script>
import sevList from "@/components/sevList"
export default {
  name: "Service",
  data() {
    return {
      intrList:[
        {
          url: require("@/assets/Images/Service/SCI_1.png"),
          content:"根据提供的研究方向和SCI期刊要求，结合当下 研究热点，协助选题、研究设计、实验、数据分析、论文写作和发表。全过程跟踪服务，最大化提升论文质量和投稿命中率。"
        },
        {
          url: require("@/assets/Images/Service/SCI_2.png"),
          content:"从语言、内容等方面进行综合评估，给出评估报告、修改意见和投稿建议。"
        },
        {
          url: require("@/assets/Images/Service/SCI_3.png"),
          content:"从语言、数据、内容等方面进行深度评估，并给出详尽的评估报告。帮助判断是否有潜力在SCI医学期刊上发表，提供专业审稿意见，修改意见和投稿建议。"
        },
        {
          url: require("@/assets/Images/Service/SCI_4.png"),
          content:"提供英语母语化润色和校对服务，从语法、拼写、用词、逻辑、语言风格等方面进行润色编辑，使稿件的语言水平满足SCI等国际期刊的投稿需求。"
        },
        {
          url: require("@/assets/Images/Service/SCI_5.png"),
          content:"更多服务..."
        }
      ]
    }
  },
  components:{
    sevList
  }
}
</script>

<style scoped lang="scss">
.sev{
  .con_wrap {
    padding: 30px 50px;
    h4{
      line-height: 60px;
      font-weight: 600;
    }
  }
  .intr{
    padding: 30px 50px;
    &.grid{
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
    &.bg{
      background-color: $defaultBgColor;
    }
    .item{
      padding: 20px 30px;
      &.bg_blue{
        background-color: #347ec6;
        color: #fff;
      }
    }
    img{
      width: 100%;
    }
  }
  .intr2{
    /*display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-template-rows: 300px 300px;*/
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    flex-wrap: wrap;
    grid-gap: 50px;
    .item{
      display: inline-block;
      width: 250px;
      text-align: center;
    }
  }
  @media screen and (max-width: 1000px) {
    .con_wrap{
      padding: 20px 30px;
      .list_con .grid .cell h4 {
        font-size: 20px;
      }
    }
    .intr{
      padding: 0;
      &.grid{
        display: block;
      }
      .item{
        &.bg2{
          background-color: #347ec6;
          color: #fff;
        }
        &.no_bg{
          background-color: unset;
          color: #000;
        }
      }
    }
    .intr2{
      justify-content: center;
      .item{
        width: 150px;
        font-size: 14px;
        grid-gap: 20px;
      }
    }
  }
}
</style>
