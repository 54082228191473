<template>
  <div class="content">
    <div class="con_wrap">
      <div class="item" v-for="(item,index) in list" :key="index">
        <div class="title">{{item.title}}</div>
        <div class="int">
          <ul>
            <li v-for="(int,i) in item.intList" :key="i">{{int}}</li>
          </ul>
        </div>
        <div class="pop">
          <p>{{item.pop}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "tDemo",
  data() {
    return {
      list:[
        {
          title:"临床问题",
          intList:["流行病学回顾","治疗手段现状"],
          pop:"",
          bigTitle:"要解决的临床问题是什么?"
        },
        {
          title:"科研问题",
          intList:["国内外基础研究现","20大科研热点；表型热点、机制热点"],
          pop:"文献"
        },
        {
          title:"明星分子、通路",
          intList:["科研热点确认：新报、动物模型","明星分子、信号通路确定(例如分子B、通 路D)"],
          pop:"生信→实验"
        },
        {
          title:"分子相关性",
          intList:["A.B、C.D等表达相关性","A.B.C、D等和临床形状相关性","A.B.C、D上下游功能性关系"],
          pop:"生 信→实验"
        },
        {
          title:"分子机制",
          intList:["明星分子、估号通路等调控方式","物 理性结合、 单因素变量验证"],
          pop:"生信→体外实验"
        }
      ]
    }
  }
}
</script>

<style scoped lang="scss">
$bordderColor: #999;
.content{
  //padding: 30px 50px;
  .con_wrap{
    .item{
      display: flex;
      align-items: center;
      justify-content: space-between;
      grid-gap: 20px;
      margin-bottom: 20px;
      .title{
        width: 200px;
        height: 60px;
        text-align: center;
        line-height: 60px;
        font-size: 24px;
        font-weight: 500;
        border: 1px solid $bordderColor;
      }
      .int{
        flex: 1;
        padding: 10px;
        border: 1px solid $bordderColor;
      }
      .pop{
        border: 1px solid $bordderColor;

      }
    }
  }
}
</style>
