<template>
  <div class="talent">
    <div class="content">
      <h1>用人理念</h1>
      <div class="de clearfix">
        <div class="int">
          <h2>欢迎来到博朗</h2>
          <p>博朗致力于为所有的科研工作者提供最优质的服务和高品 质的产品,为精准医疗贡献力量。</p>
          <p>本着以人为本、求实创新、合作共赢的理念,我们旨在更好地吸引、激励和保留人才，以支持公司的长期运营和发展。尊重人才就业，期待您的加入。</p>
        </div>
        <div class="img">
          <img :src="require('@/assets/Images/img.jpg')" alt="">
        </div>
      </div>
    </div>
    <div class="content">
      <h1>诚聘英才</h1>
      <div class="con_wrap">
        <itemTitle title="医学高级分析师"></itemTitle>
        <div class="item">
          <h5>岗位职责：</h5>
          <ul>
            <li>1:熟悉生物医学信息学，对公共数据库 的数据采集、分析，有编辑基础优先</li>
            <li>2:分析实验样品的组学测序结果，解 读、差异基因、蛋白和通路</li>
            <li>3:与研究员合作、分析、解读实验数据， 并提供统计学支持</li>
            <li>4:根据课题质量要求，完善课题实验数 据，或修改文章。</li>
          </ul>
        </div>
        <div class="item">
          <h5>任职要求：</h5>
          <ul>
            <li>1:细胞生物学、生物化学与分子生物学、 遗传学、基础临床学等相关专业研究生以上学历</li>
            <li>2:具有优异的英语写作能力</li>
            <li>3:熟悉常规生物分析软件，了解Q-PCR、 免疫组化、蛋白印迹、载体构建、蛋白表达、纯化、PT-PCR、细胞培养、流式细胞，激光共聚集等基础生物医学技术经验者优先</li>
            <li>4:有Sci写作或课题经验者优先</li>
            <li>5:有良好的生物医学相关学术背景和沟通能力，耐心细致，具有强烈的责任心</li>
          </ul>
        </div>
      </div>
      <div class="con_wrap">
        <itemTitle title="实验员"></itemTitle>
        <div class="item">
          <h5>岗位职责：</h5>
          <ul>
            <li>1:负责根据公司的计划及要求、进行细胞间的培养工作</li>
            <li>2:熟练使用相关仪器设备，负责常规仪器设备的使用及维护</li>
            <li>3:对细胞进行管理，提供技术平台要求的符合实验标准的细胞</li>
            <li>4:认真完成本岗位的实验纪律以及相关数据的初步分析统计工作</li>
            <li>5:负责实验室洁净区清洁、消毒、环境监测和环境维护工作</li>
            <li>6:能配合团队完成其他日常性工作，服从上级其它安排</li>
          </ul>
        </div>
        <div class="item">
          <h5>任职要求：</h5>
          <ul>
            <li>1:大专及以上学历，生物工程、生物制药、医学检验等相关专业</li>
            <li>2:有无菌实验细胞培养经验，尤其有干细胞等培养经验优先</li>
            <li>3:能通过学习快速掌握或熟悉细胞提取、细胞培养、细胞冻存、细胞复苏等基本操作</li>
            <li>4:专业技术强，有钻研精神、能独立操作，具有分析问题及解决问题的能力</li>
            <li>5:工作认真、责任心强，有团队合作精神</li>
          </ul>
        </div>

      </div>
      <div class="con_wrap">
        <itemTitle title="医学编辑"></itemTitle>
        <div class="item">
          <h5>岗位职责：</h5>
          <ul>
            <li>1:熟悉生物医学信息学，对公共数据库的数据采集、分析，有编辑基础优先</li>
            <li>2:分析实验样品的组学测序结果，解读、差异基因、蛋白和通路</li>
            <li>3:与研究员合作、分析、解读实验数据，并提供统计学支持</li>
            <li>4:根据课题质量要求，完善课题实验数据，或修改文章</li>
          </ul>
        </div>
        <div class="item">
          <h5>任职要求：</h5>
          <ul>
            <li>1:细胞生物学、生物化学与分子生物学、遗传学、基础临床学等相关专业研究生以上学历</li>
            <li>2:具有优异的英语写作能力</li>
            <li>3:熟悉常规生物分析软件，了解Q-PCR、免疫组化、蛋白印迹、载体构建、蛋白表达、纯化、PT-PCR、细胞培养、流式细胞，激光共聚集等基础生物医学技术经验者优先</li>
            <li>4:有Sci写作或课题经验者优先</li>
            <li>5:有良好的生物医学相关学术背景和沟通能力,耐心细致，具有强烈的责任心</li>
          </ul>
        </div>


      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Talent"
}
</script>

<style scoped lang="scss">
.talent{
  .content{
    padding: 30px 50px;
    margin-bottom: 20px;
    h2{
      font-size: 24px;
      font-weight: 500;
    }
    .de{
      display: flex;
      align-items: center;
      justify-content: space-between;
      .img{
        width: 50%;
        float: left;
        img{
          width: 100%;
        }
      }
    }
    .int{
      flex: 1;
      float: right;
      line-height: 2;
      padding-right: 20px;
    }
    .con_wrap{
      line-height: 2;
      .item{
        margin-bottom: 20px;
      }
    }
  }
  @media screen and (max-width: 1000px) {
    .content{
      .de{
        display: block;
        .img{
          width: 100%;
          max-height: 200px;
          overflow: hidden;
          img{
            margin-top: -50px;
          }
        }
      }
    }
  }
}
</style>
