<template>
  <div class="content">
    <div class="module">
      <h1>三大服务优势</h1>
      <div class="serve">
        <div class="se_item clearfix">
          <div class="img">
            <img :src="require('@/assets/Images/home/server1.jpg')" alt="">
          </div>
          <div class="info">
            <div class="">
              <p class="title">强大的项目进度管理系统，实时查看项目进度</p>
              <p class="text">我们拥有完善的项目进度管理系统，让您随时随地查看项目进度，提前 预览实验结果，您的项目就在您身边。</p>
            </div>
          </div>
        </div>
        <div class="se_item clearfix reverse">
          <div class="info">
            <div class="">
              <p class="title">顶级技术团队，专注整体解决方案</p>
              <p class="text">我们拥有南京大学，南京医科大学，沈阳药科大学，江苏大学等名校的精英组成的专业技术团队，为您量身打造整体项目解决方案，从此课题不愁。</p>
            </div>
          </div>
          <div class="img">
            <img :src="require('@/assets/Images/home/server2.jpg')" alt="">
          </div>
        </div>
        <div class="se_item clearfix">
          <div class="img">
            <img :src="require('@/assets/Images/home/server3.jpg')" alt="">
          </div>
          <div class="info">
            <div class="">
              <p class="title">透明化实验管理体系，原始数据一手掌握</p>
              <p class="text">我们拥有规范化的细胞，分子，动物实验室，工作时间可提供您参观交流，查看您的实验记录以及原始数据，尊重事实最重要。</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="module">
      <h1>核心技术平台</h1>
      <div class="md_wrap" v-for="(item,index) in list1" :key="index">
        <itemTitle :title="item.title"></itemTitle>
        <p style="padding-left: 25px">{{item.content}}</p>
        <div class="md_info">
          <div class="img_item" v-for="(img,imgIndex) in item.imgList" :key="imgIndex">
            <img :src="img.url" alt="">
            <p>{{img.name}}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="module">
      <h1>生物3D打印技术</h1>
      <div class="md_wrap md_wrap_2">
        <div class="md_img">
          <img :src="require('@/assets/Images/home/module_2_2.jpg')" alt="">
        </div>
        <div class="des">
          <p>生物3D打印是以按需设计的三维模型为基础， 通过软件分层离散和数控成型的方法，定位 装配生物材料（活细胞亦可混合在定制的生 物材料中），制造人工植入支架、组织器官 和医疗辅具等生物医学产品的快速成型技术。
          </p>
        </div>
      </div>
      <div class="md_wrap md_wrap_2">
        <div class="md_img">
          <img :src="require('@/assets/Images/home/module_2_3.jpg')" alt="">
        </div>
        <div class="des">
          <p>为生命科学和材料科学等领域的研究者提 供新的研究手段及研究工具：通过生物3D打 印技术，将生物材料或活细胞打印成组织模型，可用于组织工程、肿瘤治疗、干细胞、再生医学、医用高分子材料等领域的研究。
          </p>
        </div>
      </div>
      <div class="md_wrap md_wrap_2">
        <div class="md_img">
          <img :src="require('@/assets/Images/home/module_2_1.jpg')" alt="">
        </div>
        <div class="des">
          <p>为器官缺损患者打印人工组织器官或组织修复支架：仿照组织器官的解剖结构，用生物材料打印出组织修复支架或者用活细胞打印出具有功能的组织器官，可修复或替换患者的患病组织或器官。
          </p>
        </div>
      </div>
      <div class="md_wrap md_wrap_2">
        <div class="md_img">
          <img :src="require('@/assets/Images/home/module_2_4.jpg')" alt="">
        </div>
        <div class="des">
          <p>为制药公司打印药物筛选模型和新型药物控释支架：打印具有功能的人体组织模型，用于药物体外筛选和毒性试验，打印可自由 设计结构的药物控释支架，为制药公司提供新的药物开发和试验技术，为药物临床前试验提供新的可靠的试验载体。
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeView",
  data() {
    return {
      list1:[
        {
          title:"微流控技术",
          isImportant: true,
          content:"一种精确控制和操控微尺度流体，尤其特指亚微米结构的技术， 又称其为芯片实验室(Lab-on-a-Chip)或微全分析系统（micro- Total Analytical System）是把生物、化学、医学分析过程的样 品制备、反应、分离、检测等基本操作单元集成到一块微米尺度 的芯片上，自动完成分析全过程。 在生物医学领域两个重要的应用方向是临床诊断仪器和体外仿生模型。",
          imgList:[
            {
              url:require('@/assets/Images/home/module_1_1.jpg'),
              name:"CTC循环肿瘤芯片"
            },
            {
              url:require('@/assets/Images/home/module_1_2.jpg'),
              name:"基于3D打印的微物流控芯片"
            }
          ]
        },
        /*{
          title:"临床诊断",
          content:"微流控检测芯片一般具有样品消耗少、检测速度快、操作简便、多功能集成、体小和便于携带等优点，因此特别适合发展床边（POC）诊断，具有简化诊断流程、提高医疗结果的巨大潜力",
          imgList:[
            {
              url:require('@/assets/Images/home/module_1_3.jpg'),
              name:"模块化微物流控芯片设计"
            }
          ]
        },
        {
          title:"体外模型",
          content:"利用仿生微结构和水凝胶等生物材料，微流控芯片非常适合在体外实现组织和器官水平的生理功能，被称为“器官芯片(Organs-on-Chips)。",
          imgList:[
            {
              url:require('@/assets/Images/home/module_1_4.jpg'),
              name:"复合多层液滴控制"
            }
          ]
        }*/
      ]
    }
  }
}
</script>

<style scoped lang="scss">
.content {
  padding: 20px 30px;


  .serve {
    margin-top: 50px;
  }

  .se_item {
    height: 250px;
    position: relative;
    margin-bottom: 150px;

    .img {
      width: 300px;
      height: 300px;
      border-radius: 50%;
      border: 1px solid #59bc93;
      overflow: hidden;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);

      img {
        width: 100%;
        height: 100%;
      }
    }

    .info {
      width: calc(100% - 150px);
      float: right;
      height: 100%;
      color: #000;
      padding: 20px 30px 20px 180px;
      background-color: $defaultBgColor;
      border-radius: 10px;
      display: flex;
      align-items: center;

      .title {
        font-size: 24px;
        font-weight: 600;
      }

      .text {
        font-size: 16px;
      }
    }

    &.reverse {
      .img {
        right: 0;
      }

      .info {
        float: unset;
        padding: 20px 180px 20px 30px;
        line-height: 2;
      }
    }
  }
  .module{
    .md_wrap{

    }
    h4{
      font-size: 24px;
      font-weight: 500;
    }
    .md_info{
      padding: 20px;
      .img_item{
        display: inline-block;
        margin-right: 20px;
        text-align: center;
      }
      img{
        height: 100px;
        width: auto;
      }
    }
    .md_wrap_2{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 10px 0;
      border-bottom: 1px dashed #59bc93;
      &:last-child{
        border: none;
      }
      .md_img{
        width: 400px;
        overflow: hidden;
        img{
          width: 100%;
        }
      }
      .des{
        flex: 1;
        margin-left: 20px;
      }
    }
  }

  @media screen and (max-width: 1000px) {
    .se_item{
      height: 150px;
      margin-bottom: 80px;
      .img{
        width: 150px;
        height: 150px;
      }
      .info{
        width: calc(100% - 75px);
        padding: 20px 20px 20px 85px;
        .title{
          font-size: 18px;
        }
        .text{
          font-size: 12px;
        }
      }
      &.reverse{
        .info {
          padding: 20px 85px 20px 20px;
        }
      }
    }
    .module{
      .md_info{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .img_item{
          width: 50%;
          overflow: hidden;
          .img{
            width: 100%;
            height: auto;
          }
        }
      }
      .md_wrap_2{
        .md_img{
          width: 120px;
        }
        .des{
          font-size: 14px;
        }
      }
    }
  }
}
</style>
