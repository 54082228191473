<template>
  <div class="ctt">
    <div class="ct_content">
      <div class="int">
        <div class="info">
          <h2>联系我们</h2>
          <p class="name">南京博朗生物科技有限公司</p>
          <p>电话：13605155147(经理)</p>
          <p>邮箱：weixiangwei8888@163.com</p>
          <p>地址：南京市栖霞区生命科技园B6</p>
        </div>
        <div class="address">
          <img :src="require('@/assets/Images/contact/img_2.jpg')" alt="">
        </div>
      </div>
    </div>
    <div class="ct_content">
      <h1>在线留言</h1>
      <div class="forms flex">
        <div class="inputs">
          <div class="item">
            <input v-model="form.Name" type="text" placeholder="您的姓名">
          </div>
          <div class="item">
            <input v-model="form.PhoneNumber" type="text" placeholder="您的电话">
          </div>
          <div class="item">
            <input v-model="form.Email" type="text" placeholder="您的邮箱地址">
          </div>
        </div>
        <div class="t_area">
          <div class="item">
            <textarea v-model="form.Content" type="text" placeholder="留言内容"></textarea>
          </div>
        </div>
      </div>
      <div class="btn">
        <button @click="submit">提交</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Contact",
  data() {
    return {
      form:{
        Name:"",
        PhoneNumber:"",
        Email: "",
        Content:""
      }
    }
  },
  methods:{
    submit() {
      for(let key in this.form) {
        if(!this.form[key]) {
          alert("请填写完整内容！")
          return;
        }
      }
      if (!/^1[3456789]\d{9}$/.test(this.form.PhoneNumber)) {
        alert("请填写正确的手机号！")
        return;
      }
      if(!/^[a-zA-Z\d_-]+@[a-zA-Z0\d_-]+(\.[a-zA-Z\d_-]+)+$/.test(this.form.Email)) {
        alert("请填写正确邮箱地址！")
        return;
      }
    }
  }
}
</script>

<style scoped lang="scss">
.ct_content{
  padding: 50px;

  h2{
    font-size: 26px;
    font-weight: 700;
    border-bottom: 2px solid rgba(51, 140, 234, 0.3);
  }
  .int{
    height: 350px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .info{
    width: 550px;
    height: 100%;
    background-color: #004ea1;
    color: #fff;
    padding: 20px;
    font-size: 24px;
    line-height:2;
    .name{
      font-size: 26px;
      font-weight: 500;
    }
  }
  .address{
    width: 550px;
    height: 100%;
    overflow: hidden;
    img{
      height: 100%;
    }
  }
  .con{
    color: #fff;
    font-size: 16px;
    line-height: 2;
    img{
      width: 150px;
    }
  }
  .fil{
    width: 100%;
    border-top: 1px solid rgba(255,255,255,0.2);
    padding: 20px 3%;
    text-align: center;
  }

  .forms{
    input,textarea{
      width: 100%;
      height: 50px;
      border-radius: 5px;
      margin-bottom: 20px;
      outline: none;
      border: 1px solid #999;
      padding: 0 20px;
    }
    textarea{
      font-family: "微软雅黑";
      padding: 5px 20px;
      height: 200px;
    }
    .inputs,.t_area{
      width: 45%;
    }
    &.flex{
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  .btn{
    text-align: center;
    button{
      background: #0d9ee3;
      text-align: center;
      line-height: 50px;
      color: #fff;
      width: 190px;
      font-size: 30px;
      border-radius: 50px;
      cursor: pointer;
      border: none;
      &:active{
        background-color: #0a7cb2;
      }
    }
  }
  @media screen and (max-width: 1000px) {
    .forms{
      &.flex{
        display: block;
      }
      .inputs,.t_area{
        width: 100%;
      }
    }
    .int{
      display: block;
      height: auto;
      .info,.address{
        width: 100%;
        margin-right: unset;
        img{
          width: 100%;
          height: auto;
        }
      }
    }
  }
}
</style>
