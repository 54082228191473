<template>
  <div class="list_con">
    <div class="item" v-for="(l,index) in list" :key="index">
      <myTitle :title="l.title"></myTitle>
      <div v-for="(module,mIndex) in l.moduleList" :key="mIndex">
        <div class="grid">
          <div class="cell" v-for="(t,tIndex) in module.list" :key="tIndex">
            <h4>{{ t.title }}</h4>
            <ul>
              <li v-for="(m,mIndex) in t.list" :key="mIndex">
                <span class="r"></span>
                <span>{{ m }}</span>
              </li>
            </ul>
            <h4>{{ t.title2 }}</h4>
          </div>
        </div>
        <div class="img_list">
          <div class="img_item" v-for="(img,imgIndex) in module.imgList" :key="imgIndex">
            <img :src="img.url" alt="">
            <p>{{ img.name }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import myTitle from "@/components/myTitle"

export default {
  name: "sevList",
  data() {
    return {
      list: [
        {
          title: "细胞实验服务",
          moduleList: [
            {
              list: [
                {
                  title: "细胞培养",
                  list: ["原代细胞分离与鉴定", "干细胞诱导分化", "肿瘤细胞耐药株构建", "细胞共培养", "细胞三维培养"]
                },
                {
                  title: "细胞凋亡检测",
                  list: ["凋亡小体的观察与检测", "细胞凋亡与周期流式检测", "TUNEL检测", "线粒体膜电位（JC-1）", "凋亡相关基因与蛋白研究"]
                },
                {
                  title: "病毒包装服务",
                  list: ["干扰慢病毒", "过表达慢病毒", "ADV/AAV/腺病毒", "miRNA慢病毒", "对照病毒", "侵染评价"]
                }
              ],
              imgList: [
                {
                  url: require("@/assets/Images/xibao/1.jpg"),
                  name: "细胞增殖"
                },
                {
                  url: require("@/assets/Images/xibao/2.jpg"),
                  name: "外泌体检测技术"
                },
                {
                  url: require("@/assets/Images/xibao/3.jpg"),
                  name: "细胞凋亡"
                },
                {
                  url: require("@/assets/Images/xibao/4.jpg"),
                  name: "稳转株"
                }
              ]
            },
            {
              list: [
                {
                  title: "细胞成像",
                  list: ["细胞器染色", "激光共聚集"],
                  title2: "细胞自噬检测"
                },
                {
                  title: "细胞增殖与毒性检测",
                  list: ["MTT/CCK-8检测", "SRB/EDU检测"],
                  title2: "外泌体检测"
                },
                {
                  title: "细胞转移",
                  list: ["细胞侵袭/迁移", "细胞粘附/划痕/趋化"],
                  title2: "稳转株构建"
                }
              ],
              imgList: [
                {
                  url: require("@/assets/Images/xibao/5.jpg"),
                  name: "细胞器染色"
                },
                {
                  url: require("@/assets/Images/xibao/6.jpg"),
                  name: "荧光素酶基因检测"
                },
                {
                  url: require("@/assets/Images/xibao/7.jpg"),
                  name: "细胞自噬"
                },
                {
                  url: require("@/assets/Images/xibao/8.jpg"),
                  name: "细胞的迁移"
                }
              ]
            }
          ]
        },
        {
          title: "病理学检测服务",
          moduleList: [
            {
              list: [
                {
                  title: "组织样本处理",
                  list: ["石蛆脱水包埋", "石蜡切片、冰冻切片、细胞爬片/涂片", "骨组织脱钙", "病理分析"]
                },
                {
                  title: "染色",
                  list: ["HE、MASSON、PAS、TTC、油红染色等", "免疫组化(IHC)", "免疫荧光(IF)", "组化分析"]
                },
                {
                  title: "拍照",
                  list: ["普通/荧光显微镜", "激光扫描共聚焦显微镜", "通射电镜", "扫描电镜"]
                }
              ],
              imgList: [
                {
                  url: require("@/assets/Images/bingli/1.jpg"),
                  name: "油红染色"
                },
                {
                  url: require("@/assets/Images/bingli/2.jpg"),
                  name: "免疫组化"
                },
                {
                  url: require("@/assets/Images/bingli/3.jpg"),
                  name: "激光扫描共聚焦显微镜"
                },
                {
                  url: require("@/assets/Images/bingli/4.jpg"),
                  name: "倒置生物显微镜"
                }
              ]
            }
          ]
        },
        {
          title: "核酸/蛋白分析服务",
          moduleList: [
            {
              list: [
                {
                  title: "",
                  list: ["CRISPR/Cas9基因编辑", "Pull Down检测(RNA/GST)", "EMSA凝胶阻滞迁移检测", "DNA footprinting足迹分析"]
                },
                {
                  title: "",
                  list: ["酵母双杂", "RACE/末端快速克躇", "Southern Blot(DNA印记)", "Northern Blot(RNA印记)"]
                },
                {
                  title: "",
                  list: ["SNP检测", "甲基化检测", "荧光原位杂交(FISH)", "荧光素酶报告基因检测"]
                }
              ],
              imgList: [
                {
                  url: require("@/assets/Images/danbai/1.jpg"),
                  name: "EMSA胶凝组织迁移"
                },
                {
                  url: require("@/assets/Images/danbai/2.jpg"),
                  name: "Southern Blot(DNA印记）"
                },
                {
                  url: require("@/assets/Images/danbai/3.jpg"),
                  name: "免疫共沉淀"
                },
                {
                  url: require("@/assets/Images/danbai/4.jpg"),
                  name: "荧光染色杂交"
                }
              ]
            },
            {
              list: [
                {
                  title: "",
                  list: ["免疫共沉淀(Co-IP)", "染色质免疫共沉淀(ChIP)", "RNA免疫共沉淀(RIP)"]
                },
                {
                  title: "",
                  list: ["质粒构建与抽提", "qRT-PCR检测", "Westermn Blot", "ELISA检测"]
                },
                {
                  title: "",
                  list: ["蛋白标记", "蛋白质银染", "考马斯亮蓝染色", "等温量热滴定(ITC)"]
                }
              ],
              imgList: [
                {
                  url: require("@/assets/Images/danbai/5.jpg"),
                  name: "基因组学检测"
                },
                {
                  url: require("@/assets/Images/danbai/6.jpg"),
                  name: "染色质共沉淀"
                },
                {
                  url: require("@/assets/Images/danbai/7.jpg"),
                  name: "考马斯染色"
                }
              ]
            },
            {
              list: [
                {
                  title: "基因组学检测服务",
                  list: ["高通量测序服务", "健康基因检测服务", "基因组学生信分析"]
                },
                {
                  title: "蛋白组学检测服务",
                  list: ["蛋白质鉴定/测序", "蛋白组分分析", "糖蛋白分析", "蛋白质组学生信分析"]
                },
                {
                  title: "代谢组学检测服务",
                  list: ["靶向代谢组学分析", "非靶向代谢组学分析", "脂质代谢组学分析", "代谢组学生信分析"]
                }
              ]
            }
          ]
        },
        {
          title: "动物实验服务",
          moduleList: [
            {
              list: [
                {
                  title: "裸鼠皮下成瘤",
                  list: ["裸鼠皮下成瘤(CDX模型)"]
                },
                {
                  title: "转移瘤模型",
                  list: ["肿瘤肺转移模型", "肿瘤肝转移模型"]
                },
                {
                  title: "原位肿瘤模型",
                  list: ["肝癌原位瘤模型", "乳腙癌原位瘤模型"]
                }
              ]
            },
            {
              list: [
                {
                  title: "消化系统疾病动物模型",
                  list: ["急性胃炎模型", "肠炎模型", "大鼠结肠小肠结肠炎模型"]
                },
                {
                  title: "代谢类疾病模型",
                  list: ["胰岛素抵抗模型", "糖尿病模型", "肥胖模型"]
                },
                {
                  title: "肾脏疾病模型",
                  list: ["急性肾损伤模型", "急性肾衰竭模型", "肾间质纤维化小鼠模型"]
                }
              ],
              imgList: [
                {
                  url: require("@/assets/Images/dongwu/1.jpg"),
                  name: "裸鼠"
                },
                {
                  url: require("@/assets/Images/dongwu/2.jpg"),
                  name: "肿瘤肺转移模型"
                },
                {
                  url: require("@/assets/Images/dongwu/3.jpg"),
                  name: "乳腺癌原位瘤模型"
                },
                {
                  url: require("@/assets/Images/dongwu/4.jpg"),
                  name: "肥胖老鼠"
                }
              ]
            },
            {
              list: [
                {
                  title: "心血管类疾病模型",
                  list: ["心肌缺血模型", "高血压模型", "病毒性心肌炎模型", "自发性动脉粥样硬化小鼠模型", "脑卒中模型"]
                },
                {
                  title: "炎症与自身免疫疾病模型",
                  list: ["关节炎模型(CIA)", "OVA哮喘模型", "MSU诱导痛风性关节炎模型", "急性胰腺炎模型", "MOG诱导EAE模型"]
                },
                {
                  title: "神经系统疾病模型",
                  list: ["药物脑损伤模型", "帕金森症模型", "坐骨神经损伤模型", "老年痴呆症模型", "抑郁症模型"]
                }
              ]
            },
            {
              list: [
                {
                  title: "呼吸系统疾病模型",
                  list: ["大鼠急性肺损伤模型", "慢性支气管炎模型", "特发性肺纤维化模型"]
                },
                {
                  title: "肝脏疾病模型",
                  list: ["肝纤维化模型", "慢性脂肪肝模型", "急性肝损伤模型"]
                },
                {
                  title: "疾病治疗与药效评估",
                  list: ["饲养、给药、取器官/活体成像", "血生化指标检测", "血清样本ELISA检测"]
                }
              ],
              imgList: [
                {
                  url: require("@/assets/Images/dongwu/5.jpg"),
                  name: "肺模型"
                },
                {
                  url: require("@/assets/Images/dongwu/6.jpg"),
                  name: "给药与肿瘤治疗"
                },
                {
                  url: require("@/assets/Images/dongwu/8.jpg"),
                  name: "肝脏"
                },
                {
                  url: require("@/assets/Images/dongwu/7.jpg"),
                  name: "活体成像"
                }
              ]
            },
          ]
        }
      ]
    }
  },
  components: {
    myTitle
  },
  // props:['list']
}
</script>

<style scoped lang="scss">
.list_con {
  .item {
    margin-bottom: 20px;
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    margin: 20px 0;
    background-color: #e1f3fd;

    .cell {
      padding: 10px 0 10px 20px;

      h4 {
        color: #639cc8;
        font-size: 24px;
        font-weight: 700;
        padding: 10px 0;
      }

      li {
        font-size: 16px;
        line-height: 1.5;

        .r {
          display: inline-block;
          width: 10px;
          height: 10px;
          border: 1px solid #2b6695;
          border-radius: 50%;
          position: relative;
          margin-right: 5px;

          &::after {
            content: "";
            display: block;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            background-color: #2b6695;
          }
        }
      }
    }
  }

  .img_list {
    white-space: nowrap;
    overflow-x: auto;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .img_item {
      display: inline-block;
      margin-right: 5px;
      padding: 5px 10px;
      text-align: center;

      img {
        height: 120px;
        width: auto;
      }
    }
  }

  @media screen and (max-width: 1000px) {
    .grid {
      .cell {
        h4 {
          font-size: 20px;
        }

        li {
          font-size: 14px;
        }
      }
    }
    .img_list{
      .img_item{
        font-size: 14px;
        img{
          height: 50px;
          max-width: 80px;
        }
      }
    }
  }
}
</style>
