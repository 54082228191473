<template>
  <div class="about">
    <div class="ab_content">
      <h1>关于博朗</h1>
      <div class="ind">
        <div class="text">
          <p><i class="mt_ran">◆</i>南京博朗生物科技有限公司成立于2022年1月，是一家专注于 生命科学和体外诊断试剂研发，生产，销售和生物医药技术服 务的高科技企业，主营业务为转化医学服务，科研实验设计及 开展，细胞实验服务，动物实验服务等的生物科技有限公司。
</p>
          <p><i class="mt_ran">◆</i>企业愿景:为所有的科研工作者提供最优质的服务和高品 质的产品,为精准医疗贡献力量</p>
        </div>
        <div class="imgs">
          <img v-for="(img,index) in imgList" :key="index" :src="img" alt="">
        </div>
      </div>
    </div>
    <div class="ab_content">
      <h1>我们的优势</h1>
      <div class="ind reverse">
        <div class="imgs all_bd big">
          <img v-for="(img,index) in imgList2" :key="index" :src="img" alt="">
        </div>
        <div class="text">
          <div class="item">
            <itemTitle title="强大的项目进度管理系统，实时査看项目进度"></itemTitle>
            <p>我们拥有完善的项目进度管理系统，让您随时随地查看项目进度，
              提前预览实验结果,您的项目就在您身边</p>
          </div>
          <div class="item">
            <itemTitle title="顶级技术团队，专注整体解决方案"></itemTitle>
            <p>我们拥有南京大学，南京医科大学，沈阳药科大学，江苏大学等名校的 精英组成的专业技术团队，为您量身打造整体项目解决方案，从此课题 不愁</p>
          </div>
          <div class="item">
            <itemTitle title="透明化实验管理体系，原始数据一手掌握"></itemTitle>
            <p>我们拥有规范化的细胞，分子，动物实验室，工作时间可提供您参观 交流，查看您的实验记录以及原始数据，尊重事实最重要</p>
          </div>
        </div>
        <div class="imgs all_bd small">
          <img v-for="(img,index) in imgList2" :key="index" :src="img" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutView",
  data() {
    return {
      imgList:[require('@/assets/Images/about/img.jpg'),require('@/assets/Images/about/img_1.jpg'),require('@/assets/Images/about/img_2.jpg')],
      imgList2:[require('@/assets/Images/home/server1.jpg'),require('@/assets/Images/home/server2.jpg'),require('@/assets/Images/home/server3.jpg')]
    }
  }
}
</script>

<style scoped lang="scss">
.about{
  .mt_ran{
    color: $defaultRangeColor;
  }
  .ab_content{
    padding: 30px 50px;
  }
  .ind{
    width: 100%;
    padding: 60px 75px;
    background-color: $defaultBgColor;
    color: #666;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .imgs{
      width: 300px;
      height: auto;
      img{
        width: 200px;
        &:nth-child(2n) {
          float: right;
        }
        &:nth-child(3) {
          border: 2px solid #33587a;
          border-radius: 20px;
        }
      }
      &.all_bd{
        img{
          border: 2px solid #33587a;
          border-radius: 20px;
        }
      }
    }
    .text{
      flex: 1;
      padding-right: 20px;
      font-size: 24px;
      font-weight: 500;
      line-height: 2;
      .item{
        margin-bottom: 20px;
      }
    }
    &.reverse{
      .imgs{
        img{
          float: left;
          &:nth-child(2n+1) {
            float: right;
          }
        }
      }
      .text{
        padding-right: unset;
        padding-left: 30px;
      }
      .small{
        display: none;
      }
    }
  }
  @media screen and (max-width: 1000px) {
    .ind{
      padding: 20px 30px;
      display: block;
      text-align: center;
      .text{
        font-size: 18px;
        text-align: left;
        padding-right: unset;
      }
      .imgs{
        width: auto;
        overflow: hidden;
        img{
          float: unset;
          &:first-child{
            margin-right: 5px;
          }
          &:nth-child(2n){
            float: unset;
          }
          &:nth-child(2n+1){
            float: unset!important;
          }
        }
      }
      &.reverse{
        .text{
          padding-left: 0;
        }
        .big{
          display: none;
        }
        .small{
          display: block;
        }
      }
    }
  }
}
</style>
