<template>
    <div class="my_title">
      <div class="range"></div>
      <div class="con">
        <span>{{ title }}</span>
      </div>
      <div class="icon">
        <img :src="require('@/assets/Images/Service/send.png')" alt="">
      </div>
    </div>
</template>

<script>
export default {
  name: "myTitle",
  data() {
    return {

    }
  },
  props:['title']
}
</script>

<style scoped lang="scss">

  .my_title{
    width: 100%;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    background-color: #0059ad;
    padding: 0 20px;
    .range{
      width: 5px;
      height: 100%;
      //margin-left: 20px;
      background-color: #fff;
    }
    .con{
      padding: 0 20px;
      flex: 1;
      height: 100%;
      line-height: 60px;
      font-weight: 700;
      font-size: 28px;
    }
    .icon{
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: #fff;
      //text-align: center;
      //line-height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      img{
        width: 30px;
        height: 30px;
      }
    }
  }
</style>
