import { createApp } from 'vue'
import App from './App.vue'
import "@/assets/css/base.scss"
const ap = createApp(App);
// import echarts from "@/assets/js/echarts"
// ap.config.globalProperties.$echarts = echarts
// import router from "@/router";
import itemTitle from "@/components/ran_h4"
ap.component("itemTitle",itemTitle)
ap.mount('#app')
