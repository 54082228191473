<template>
  <div class="tec">
    <div class="content">
      <h1>六大模块</h1>
      <div class="con_wrap">
        <div class="mdu">
          <div class="item" v-for="(item,index) in list" :key="index">
            <div class="img">
              <img :src="item.Img" alt="">
            </div>
            <p>{{ item.Name }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <h1>项目实施技术路线</h1>
      <div class="con_wrap">
        <div class="chart">
          <img src="../assets/Images/Technology/big_path.jpg" alt="">
        </div>
      </div>
    </div>
    <div class="content">
      <h1>实验平台展示</h1>
      <div class="con_wrap">
        <div class="chart">
          <img src="../assets/Images/Technology/plat_show.jpg" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "Technology",
  data() {
    return {
      list: [
        {
          Name: "细胞生物学服务平台",
          Img: require("@/assets/Images/Technology/img_1.jpg")
        },
        {
          Name: "分子生物学服务平台",
          Img: require("@/assets/Images/Technology/img_2.gif")
        },
        {
          Name: "动物模型服务平台",
          Img: require("@/assets/Images/Technology/img_3.jpg")
        },
        {
          Name: "定制合成服务平台",
          Img: require("@/assets/Images/Technology/img_4.jpg")
        },
        {
          Name: "蛋白/抗体服务平台",
          Img: require("@/assets/Images/Technology/img_5.jpg")
        },
        {
          Name: "科技论文编辑服务平台",
          Img: require("@/assets/Images/Technology/img.jpg")
        }
      ]
    }
  },
  components: {
  }
}
</script>

<style scoped lang="scss">
.tec {
  .mdu {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 100px;
    background-color: $defaultBgColor;
    padding: 30px 50px;

    .item {
      text-align: center;
      cursor: pointer;

      .img {
        display: inline-block;
        width: 150px;
        height: 150px;
        overflow: hidden;
        border-radius: 50%;
        border: 1px solid #59bc93;
        position: relative;

        img {
          height: 100%;
          width: auto;
          position: absolute;
          left: 0;
          top: 0;
          transition: all 0.8s;
        }
      }

      p {
        font-size: 20px;
        color: #666;
        font-weight: 500;
        line-height: 2;
      }

      &:hover {
        .img {
          img {
            height: 120%;
            left: -10%;
            top: -10%;
          }
        }
      }
    }
  }

  .con_wrap {
    padding: 30px 50px;

    .chart {
      width: 100%;
      img{
        width: 100%;
      }
    }
  }
}
</style>
