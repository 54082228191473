<template>
  <div class="bolang">
    <div :class="['header',fixed?'header_fixed':'']">
      <div class="logo">
        <a href="/">
          <img :src="require('@/assets/Images/logo.png')" alt="">
        </a>
      </div>
      <div class="menu-container">
        <div class="menu_icon" @click="showMenu">
          <img :src="require('@/assets/Images/menu.png')" alt="">
        </div>
        <ul :class="['menu',isShowMenu?'show':'']">
          <li v-for="(item,index) in menuList" :key="index" @click="selComponent(index)">{{ item.Name }}</li>
        </ul>
      </div>
    </div>
    <div class="place"></div>
    <div class="banner">
      <img :src="currentComponent.BannerImg" alt="">
      <div class="banner_wrap">
        <div class="f_c">
          <p class="n">{{ currentComponent.Title }}</p>
          <p class="d">{{ currentComponent.Content }}</p>
        </div>
      </div>
    </div>
    <div class="com_content">
      <component v-if="componentName" :is="componentName"></component>
    </div>
    <div class="footer">
      <div class="container">
        <div class="int">
          <div class="f_logo">
            <img :src="require('@/assets/Images/logo_footer.png')" alt="">
          </div>
          <div class="info">
            <p>电话：13605155147(经理)</p>
            <p>邮箱：weixiangwei8888@163.com</p>
            <p>地址：南京市栖霞区生命科技园B6</p>
          </div>
        </div>
        <div class="con">
          <p>联系我们:</p>
          <div>
            <img :src="require('@/assets/Images/contact.png')" alt="">
          </div>
        </div>
      </div>
    </div>
    <!--    <div class="demo" style="width: 100%;height: 100%">-->
    <!--      <router-view></router-view>-->
    <!--    </div>-->
  </div>
</template>

<script>
import components from '@/components/index'

export default {
  name: 'App',
  data() {
    return {
      fixed: false,
      menuList: [
        {
          Name: "首页",
          ComName: "HomeView",
          BannerImg: require('@/assets/Images/banner.jpg'),
          Title: "南京博朗生物科技有限公司",
          Content: "--专业整体技术方案缔造者（博采众长 朗心独见）"
        },
        {
          Name: "关于我们",
          ComName: "AboutView",
          BannerImg: require('@/assets/Images/banner.jpg'),
          Title: "南京博朗生物科技有限公司",
          Content: "--专业整体技术方案缔造者（博采众长 朗心独见）"
        },
        {
          Name: "核心技术平台",
          ComName: "Technology",
          BannerImg: require("@/assets/Images/platForm/banner.jpg"),
          Title: "核心技术平台",
          Content: "细胞生物学、分子生物学、动物模型、定制合成、蛋白/抗体、科技论文编辑"
        },
        {
          Name: "一站式服务平台",
          ComName: "Service",
          BannerImg: require("@/assets/Images/platForm/banner.jpg"),
          Title: "一站式服务平台",
          Content: "课题基金、SCI服务、实验外包"
        },
        {
          Name: "诚聘英才",
          ComName: "Talent",
          BannerImg: require("@/assets/Images/banner_3.jpg"),
          Title: "诚聘英才",
          Content: "享受工作，不断成长"
        },
        {
          Name: "在线合作",
          ComName: "Contact",
          BannerImg: require("@/assets/Images/banner_3.jpg"),
          Title: "为精准医疗贡献力量",
          Content: "为所有的科研工作者提供最优质的服务和高品质的产品"
        }
      ],
      currentComponent: "",
      componentName: "HomeView",
      isShowMenu: false
    }
  },
  components: {
    ...components
  },
  mounted() {
    this.currentComponent = this.menuList[0];
    window.addEventListener("scroll", this.scrolling)
  },
  methods: {
    // 头部导航做鼠标滚动优化
    scrolling() {
      // 滚动条距文档顶部的距离（做兼容处理）===》不懂的可以结合画图理清逻辑
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      // 滚动条滚动的距离
      let scrollStep = scrollTop - this.oldScrollTop;
      // 更新——滚动前，滚动条距文档顶部的距离
      this.oldScrollTop = scrollTop;
      this.fixed = scrollStep >= 0;
    },
    selComponent(index) {
      this.currentComponent = this.menuList[index];
      this.componentName = this.currentComponent.ComName;
      window.scrollTo(0, 0)
    },
    showMenu() {
      this.isShowMenu = !this.isShowMenu;
    }
  }
}
</script>

<style lang="scss">
.bolang {
  .header {
    width: 100%;
    height: 80px;
    background-color: #004ea1;
    padding: 0 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 100;
    transition: all 0.3s;

    .logo {
      height: 60px;

      img {
        height: 100%;
        width: auto;
      }
    }

    &.header_fixed {
      //background-color: rgba(0,0,0,0.3);
      background-color: rgba(0, 78, 161, 0.3);
    }

    .menu-container {
      .menu_icon {
        display: none;
      }

      .menu {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        li {
          margin-right: 20px;
          color: #fff;
          font-size: 16px;
          cursor: pointer;
          line-height: 2;
          position: relative;

          &::after {
            content: "";
            position: absolute;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: #fff;
            display: none;
          }

          &:hover {
            &::after {
              display: block;
            }
          }
        }
      }
    }
  }

  .banner {
    width: 100%;
    height: auto;
    position: relative;

    img {
      width: 100%;
      height: auto;
    }

    .banner_wrap {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      z-index: 2;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0 0 300px 200px rgba(0, 78, 161, 0.8) inset;

      .f_c {
        line-height: 1.5;
        color: #fff;
        text-align: center;

        .n {
          font-size: 48px;
          font-weight: 700;
        }

        .d {
          font-size: 24px;
          font-weight: 500;
        }
      }
    }
  }

  .footer {
    width: 100%;
    height: auto;
    background-color: #004ea1;
    padding: 20px 30px;

    .container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 20px;
    }

    .f_logo {
      width: 300px;

      img {
        width: 100%;
        height: auto;
      }
    }

    .info {
      margin-top: 15px;
      color: #fff;
      font-size: 16px;
      line-height: 1.5;
    }

    .con {
      color: #fff;
      font-size: 16px;
      line-height: 2;

      img {
        width: 150px;
      }
    }

    .fil {
      width: 100%;
      border-top: 1px solid rgba(255, 255, 255, 0.2);
      padding: 20px 3%;
      text-align: center;
    }
  }

  .place {
    width: 100%;
    height: 80px;
    background-color: #004ea1;
    display: none;
  }

  h1 {
    text-align: center;
    font-size: 30px;
    font-weight: 700;
    color: #000;
    line-height: 180px;
  }

  @media screen and (min-width: 1200px){
    .com_content{
      width: 1200px;
      margin: 0 auto;
    }
  }

  @media screen and (max-width: 1000px) {
    .place {
      display: block;
    }
    .banner {
      .banner_wrap {
        .f_c {
          .n {
            font-size: 36px;
          }

          .d {
            font-size: 24px;
          }
        }
      }
    }
    .header {
      .menu-container {
        position: relative;

        .menu_icon {
          display: block;
          cursor: pointer;
        }

        .menu {
          position: absolute;
          display: block;
          right: 0;
          background: rgba(0, 0, 0, 0.6);
          transition: all 0.3s;
          width: 120px;
          height: 0;
          overflow: hidden;

          &.show {
            height: 235px;
          }

          li {
            padding: 5px 10px;
            margin-right: 0;
            font-size: 14px;
            white-space: nowrap;

            &:hover {
              &::after {
                display: none;
              }

              font-weight: 700;
            }
          }
        }
      }
    }
  }
}

</style>
