<template>
  <h4><i class="mt_ran">▲</i>{{title}}</h4>
</template>

<script>
export default {
  name: "ran_h4",
  props:['title']
}
</script>

<style scoped lang="scss">
.mt_ran{
  color: $defaultRangeColor;
}
</style>
