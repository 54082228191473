<template>
  <div class="content">
    <div class="plat_form">
      <div class="chart" id="chart"></div>
    </div>
  </div>
</template>

<script>
import {getCurrentInstance} from "vue";

const data = {
  name: '拟定研究方案',
  children: [
    {
      name: '体外细胞实验',
      children: [
        {
          name: '细胞表型差异',
          children: [
            {
              name: '细胞增殖',
              isVer: true
            },
            {
              name: '细胞凋亡/周期',
              isVer: true
            },
            {
              name: '细胞迁移/侵袭',
              isVer: true
            },
            {
              name: '细胞自噬/焦亡',
              isVer: true
            },
            {
              name: '干细胞分化',
              isVer: true
            }
          ]
        }
      ]
    },
    {
      name: '临床样本',
      children: [
        {
          name: '基因/蛋白表达差异',
          children:[
            {
              name: '转录组学分析',
              isVer: true
            },
            {
              name: '基因组学分析',
              isVer: true
            },
            {
              name: '表观组学分析',
              isVer: true
            },
            {
              name: '蛋白组学分析',
              isVer: true
            }
          ]
        }
      ]
    },
    {
      name: '体内动物实验',
      children: [
        {
          name: '动物水平差异',
          children: [
            {
              name: '动物疾病模型',
              isVer: true
            },
            {
              name: '药物体内药效',
              isVer: true
            }
          ]
        }
      ]
    }
  ]
}
const data2 = {
  name: '拟定研究方案',
  children: [
    {
      name: '体外细胞实验',
      children: [
        {
          name: '细胞表型差异',
          children: [
            {
              name: '细胞增殖',
              isVer: true
            },
            {
              name: '细胞凋亡/周期',
              isVer: true
            },
            {
              name: '细胞迁移/侵袭',
              isVer: true
            },
            {
              name: '细胞自噬/焦亡',
              isVer: true
            },
            {
              name: '干细胞分化',
              isVer: true
            }
          ]
        }
      ]
    },
    {
      name: '临床样本',
      children: [
        {
          name: '基因/蛋白表达差异',
          children:[
            {
              name: '转录组学分析',
              isVer: true
            },
            {
              name: '基因组学分析',
              isVer: true
            },
            {
              name: '表观组学分析',
              isVer: true
            },
            {
              name: '蛋白组学分析',
              isVer: true
            }
          ]
        }
      ]
    },
    {
      name: '体内动物实验',
      children: [
        {
          name: '动物水平差异',
          children: [
            {
              name: '动物疾病模型',
              isVer: true
            },
            {
              name: '药物体内药效',
              isVer: true
            }
          ]
        }
      ]
    }
  ]
}
export default {
  name: "PlatForm",
  data() {
    return {
      eCharts: null,
      option: {
        backgroundColor: "#fbfbfb",
        tooltip: {
          trigger: 'item',
          triggerOn: 'mousemove'
        },
        series: [
          {
            type: 'tree',
            id: 0,
            name: 'tree1',
            data: [data],
            top: '10%',
            left: '5%',
            bottom: '55%',
            right: '5%',
            symbolSize: 7,
            edgeShape: 'polyline',
            orient: 'vertical',
            edgeForkPosition: '63%',
            initialTreeDepth: 3,
            zoom: 0.8, //当前视角的缩放比例
            roam: false, //是否开启平游或缩放  // 是否开启鼠标缩放或平移，默认false
            label: {
              // 每个节点对应的文本标签样式
              show: true, // 是否显示标签
              distance: 8, // 文本距离图形元素的距离
              position: ['50%', '50%'], // 标签位置
              verticalAlign: 'middle', // 文字垂直对齐方式，默认自动，top，middle，bottom
              align: 'center', // 文字水平对齐方式，默认自动，left，right，center
              fontSize: 16, // 字体大小
              color: '#333', // 字体颜色
              // color: 'red', // 字体颜色
              backgroundColor: '#F8F9FA', // 文字块的背景颜色
              borderColor: '#CED4DA', // 文字块边框颜色
              borderWidth: 1, // 文字块边框宽度
              borderType: 'solid', // 文字块边框描边类型 solid dashed dotted
              borderRadius: 2, // 文字块的圆角
              padding: [6, 12], // 文字块内边距
              shadowColor: 'rgba(0,121,221,0.3)', // 文字块的背景阴影颜色
              shadowBlur: 6, // 文字块的背景阴影长度
              formatter: (params) => {
                if (!params.data.isVer) {
                  return `{name|${params.data.name}}`
                }
                let newName = ''
                let len = params.data.name.length
                let strLen = 1 //一行显示几个字
                let rowNum = Math.ceil(len / strLen)
                if (len > strLen) {
                  for (let p = 0; p < rowNum; p++) {
                    let tempStr = ''
                    let start = p * strLen
                    let end = start + strLen
                    if (p === rowNum - 1) {
                      tempStr = params.data.name.substring(start, len)
                    } else {
                      tempStr = params.data.name.substring(start, end) + '\n'
                    }
                    newName += tempStr
                  }
                } else {
                  newName = params.data.name
                }
                let n = newName
                if (n.length > 16) {
                  n = n.slice(0, 16) + '...'
                }
                return `{name|${n}}`
              },
              rich: {
                name: {
                  fontSize: 16,
                  padding: 0,
                  fontWeight: 700,
                  borderRadius: 2
                }
              }
            },
            lineStyle: {
              // 树图边的样式
              color: 'rgba(255,0,0,.35)', // 树图边的颜色
              width: 2, // 树图边的宽度
              // curveness: 1, // 树图边的曲度
              shadowColor: 'rgba(0, 0, 0, 0.5)' // 阴影颜色
            },
            leaves: {
              label: {
                position: 'middle',
                verticalAlign: 'middle',
                align: 'middle'
              }
            },
            expandAndCollapse: true,
            animationDuration: 550,
            animationDurationUpdate: 650
          },
          {
            type: 'tree',
            id: 1,
            name: 'tree2',
            data: [data2],
            top: '60%',
            left: '5%',
            bottom: '5%',
            right: '5%',
            symbolSize: 7,
            edgeShape: 'polyline',
            orient: 'vertical',
            edgeForkPosition: '63%',
            initialTreeDepth: 3,
            zoom: 0.8, //当前视角的缩放比例
            roam: false, //是否开启平游或缩放  // 是否开启鼠标缩放或平移，默认false
            label: {
              // 每个节点对应的文本标签样式
              show: true, // 是否显示标签
              distance: 8, // 文本距离图形元素的距离
              position: ['50%', '50%'], // 标签位置
              verticalAlign: 'middle', // 文字垂直对齐方式，默认自动，top，middle，bottom
              align: 'center', // 文字水平对齐方式，默认自动，left，right，center
              fontSize: 16, // 字体大小
              color: '#333', // 字体颜色
              // color: 'red', // 字体颜色
              backgroundColor: '#F8F9FA', // 文字块的背景颜色
              borderColor: '#CED4DA', // 文字块边框颜色
              borderWidth: 1, // 文字块边框宽度
              borderType: 'solid', // 文字块边框描边类型 solid dashed dotted
              borderRadius: 2, // 文字块的圆角
              padding: [6, 12], // 文字块内边距
              shadowColor: 'rgba(0,121,221,0.3)', // 文字块的背景阴影颜色
              shadowBlur: 6, // 文字块的背景阴影长度
              formatter: (params) => {
                if (!params.data.isVer) {
                  return `{name|${params.data.name}}`
                }
                let newName = ''
                let len = params.data.name.length
                let strLen = 1 //一行显示几个字
                let rowNum = Math.ceil(len / strLen)
                if (len > strLen) {
                  for (let p = 0; p < rowNum; p++) {
                    let tempStr = ''
                    let start = p * strLen
                    let end = start + strLen
                    if (p === rowNum - 1) {
                      tempStr = params.data.name.substring(start, len)
                    } else {
                      tempStr = params.data.name.substring(start, end) + '\n'
                    }
                    newName += tempStr
                  }
                } else {
                  newName = params.data.name
                }
                let n = newName
                if (n.length > 16) {
                  n = n.slice(0, 16) + '...'
                }
                return `{name|${n}}`
              },
              rich: {
                name: {
                  fontSize: 16,
                  padding: 0,
                  fontWeight: 700,
                  borderRadius: 2
                }
              }
            },
            lineStyle: {
              // 树图边的样式
              color: 'rgba(255,0,0,.35)', // 树图边的颜色
              width: 2, // 树图边的宽度
              // curveness: 1, // 树图边的曲度
              shadowColor: 'rgba(0, 0, 0, 0.5)' // 阴影颜色
            },
            leaves: {
              label: {
                position: 'middle',
                verticalAlign: 'middle',
                align: 'middle'
              }
            },
            expandAndCollapse: true,
            animationDuration: 550,
            animationDurationUpdate: 650
          }
        ]
      }
    }
  },
  mounted() {
    this.eCharts = getCurrentInstance()?.appContext.config.globalProperties.$echarts
    let myChart = this.eCharts.init(document.getElementById('chart'));
    myChart.setOption(this.option, true);
  }
}
</script>

<style scoped lang="scss">
.content {
  width: 100%;
  height: 100%;

  .plat_form {
    height: 1400px;

    .chart {
      height: 100%;
    }
  }
}
</style>
